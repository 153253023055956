<script setup>
import { useValidation } from "~/composables/useValidation";
import { transl } from "@/composables/useTranslation";

const props = defineProps(["modelValue", "rules", "value"]);
const emit = defineEmits(["update:modelValue"]);

const radioValue = ref(false);

function watchProps() {
  watch(
    () => props.modelValue,
    () => {
      radioValue.value = !!props.modelValue;
    },
    { immediate: true }
  );
  watchEffect(() => {
    emit("update:modelValue", radioValue.value);
  });
}

function handleOnChange() {
  validate();
}

onMounted(() => {
  if (form) form?.register(validationModel);

  watchProps();
});

onUnmounted(() => {
  if (form) form.unregister(validationModel);
});

// validation
const form = inject("form", null);

const validationModel = useValidation(
  computed(() => radioValue.value),
  props.rules
);

const { hasErrors, errors, validate } = validationModel;

// expose
defineExpose({
  hasErrors,
  errors,
  validate,
});
</script>
<template>
  <div class="v-radio-field" :class="{ error: hasErrors }">
    <input
      type="radio"
      v-model="radioValue"
      :value="props.value"
      :checked="radioValue"
      @change="handleOnChange"
    />
    <div class="radio-label"></div>

    <slot name="error">
      <!-- error -->
      <v-error :class="{ active: hasErrors }" class="error-el">
        {{ transl(errors[0]) }}</v-error
      >
    </slot>
  </div>
</template>

<style scoped>
.v-radio-field {
  display: inline-flex;
  align-items: center;
  /* flex: 1; */
  /* margin: 0.5rem; */
  position: relative;
  border: 1px solid #b4b4b4;
  border-radius: 100%;
}

.v-radio-field.error {
  border: 2px solid #ff5252 !important;
  border-radius: 50%;
}

.v-radio-field input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin: 0 !important;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;

  box-sizing: border-box;
}

.v-radio-field input[type="radio"] + .radio-label:before {
  margin: 0;
  content: "";
  background: #fff;
  border-radius: 100%;

  display: inline-block;
  min-width: 30px;
  min-height: 30px;
  position: relative;
  /* top: -0.2em; */
  /* margin-right: 1em; */
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
  /* top: 5px; */
}

.v-radio-field input[type="radio"]:checked + .radio-label:before {
  background: linear-gradient(180deg, #ffb649, #e78f0c);
  box-shadow: inset 0 0 0 6px #fff;
  border-radius: 100%;
}
.v-radio-field input[type="radio"]:focus + .radio-label:before {
  background: linear-gradient(180deg, #ffb649, #e78f0c);
  box-shadow: inset 0 0 0 6px #fff;
  border-radius: 100%;
}

.v-radio-field input[type="radio"]:focus {
}

.v-radio-field input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #e3e3e3;
  border-color: #e3e3e3;
  background: #fff;
  border-radius: 100%;
}

.v-radio-field input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}

.radio-label {
  display: flex;
  align-items: start;
  pointer-events: none;
  width: 30px;
  height: 30px;
}

.error-el {
  padding: 10px;
  margin-left: 20px;
  margin-top: 5px;
  z-index: 1;
}

/* .error .error-el{
  border: 1px solid #ff5252 !important;
} */
</style>
